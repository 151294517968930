import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children:[
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../views/content/Dashboard.vue')
      },
      {
        path: '/city/:id',
        name: 'City',
        component: () => import('../views/content/City.vue'),
        props: true
      },
      {
        path: '/authority',
        name: 'Authority',
        component: () => import('../views/content/Authority.vue')
      },
      {
        path: '/mission',
        name: 'Mission',
        component: () => import('../views/content/Mission.vue')
      },
      {
        path: '/aplications',
        name: 'Aplications',
        component: () => import('../views/content/Aplications.vue')
      },
      {
        path: '/resolutions',
        name: 'Resolution',
        component: () => import('../views/content/Resolution.vue')
      },
      {
        path: '/protocols',
        name: 'Protocol',
        component: () => import('../views/content/Protocol.vue')
      },
      {
        path: '/reglaments',
        name: 'Reglament',
        component: () => import('../views/content/Reglament.vue')
      },
      {
        path: '/manuals',
        name: 'Manual',
        component: () => import('../views/content/Manual.vue')
      },
      {
        path: '/convenios',
        name: 'Convenio',
        component: () => import('../views/content/Convenio.vue')
      },
      {
        path: '/rrhh',
        name: 'Rrhh',
        component: () => import('../views/content/Rrhh.vue')
      },
      {
        path: '/ejecution',
        name: 'EjecPresupuestaria',
        component: () => import('../views/content/EjecPresupuestaria.vue')
      },
      {
        path: '/rendition',
        name: 'Rendition',
        component: () => import('../views/content/Rendition.vue')
      },
      {
        path: '/audit',
        name: 'Audit',
        component: () => import('../views/content/Audit.vue')
      },
      {
        path: '/poa',
        name: 'Poa',
        component: () => import('../views/content/Poa.vue')
      },
      {
        path: '/apoyo',
        name: 'ProcedimientoApoyo',
        component: () => import('../views/content/ProcedimientoApoyo.vue')
      },
      {
        path: '/legal',
        name: 'Legal',
        component: () => import('../views/content/Legal.vue')
      },
      {
        path: '/psicologia',
        name: 'Psicologia',
        component: () => import('../views/content/Psicologia.vue')
      },
      {
        path: '/social',
        name: 'Social',
        component: () => import('../views/content/Social.vue')
      },
      {
        path: '/sgc',
        name: 'SGC',
        component: () => import('../views/content/SGC.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
